import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'

// Fixed permissions for now, will be dynamic in the future
const permissions = [
  'FAZENDA_EXCLUIR',
  'FAZENDA_VISUALIZAR',
  'FAZENDA_EDITAR',
  'FAZENDA_CADASTRAR',
  'FAZENDA_LISTAR',
  'CULTIVAR_CADASTRAR',
  'CULTIVAR_EDITAR',
  'CULTIVAR_EXCLUIR',
  'CULTIVAR_LISTAR',
  'CULTIVAR_VISUALIZAR',
  'FERTILIZANTE_CADASTRAR',
  'FERTILIZANTE_EDITAR',
  'FERTILIZANTE_EXCLUIR',
  'FERTILIZANTE_LISTAR',
  'FERTILIZANTE_VISUALIZAR',
  'DEFENSIVO_CADASTRAR',
  'DEFENSIVO_EDITAR',
  'DEFENSIVO_EXCLUIR',
  'DEFENSIVO_LISTAR',
  'DEFENSIVO_VISUALIZAR',
  'SEMEADURA_LISTAR',
  'SEMEADURA_CADASTRAR',
  'SEMEADURA_EDITAR',
  'SEMEADURA_EXCLUIR',
  'SEMEADURA_VISUALIZAR',
  'COLHEITA_LISTAR',
  'COLHEITA_CADASTRAR',
  'COLHEITA_EDITAR',
  'COLHEITA_EXCLUIR',
  'DASHBOARD_FENOLOGICOS',
  'DASHBOARD_GERAL',
  'DASHBOARD_GRAFICO_IPM',
  'DASHBOARD_AGENDA',
  'PLANEJAMENTO_LISTAR',
  'PLANEJAMENTO_CADASTRAR',
  'PLANEJAMENTO_EDITAR',
  'PLANEJAMENTO_EXCLUIR',
  'PLANEJAMENTO_VISUALIZAR',
  'EXECUCAO_LISTAR',
  'EXECUCAO_CADASTRAR',
  'EXECUCAO_EDITAR',
  'EXECUCAO_EXCLUIR',
  'EXECUCAO_VISUALIZAR',
  'CONTAS_EDITAR',
  'RELATORIO_FAZENDA_PULVERIZACAO',
  'RELATORIO_SEMEADURA',
  'RELATORIO_EXECUCAO_PULVERIZACAO',
  'RELATORIO_DEFENSIVOS_UTILIZADOS',
  'MENU_CADASTROS',
  'MENU_SAFRAS',
  'MENU_ADMINISTRACAO',
  'MENU_PAINEIS',
  'MENU_RELATORIOS',
  'ADJUVANTES_LISTAR',
  'ADJUVANTES_EDITAR',
  'ADJUVANTES_CADASTRAR',
  'ADJUVANTES_VISUALIZAR',
  'ADJUVANTES_EXCLUIR',
  'TALHAO_LISTAR',
  'TALHAO_CADASTRAR',
  'TALHAO_EDITAR',
  'TALHAO_VISUALIZAR',
  'TALHAO_EXCLUIR',
  'PERFIL_LISTAR',
  'NOTE_LISTAR',
  'NOTE_CADASTRAR',
  'NOTE_EDITAR',
  'NOTE_EXCLUIR',
  'CATALOG_LISTAR',
  'MANAGER_ACCOUNT',
]

const authRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/permissions',
      result: () => {
        return APIFailureWrapper({
          content: permissions,
          errorMessage: 'Erro na listagem de categorias de anotações',
        })
      },
    },
    'on'
  ),
]

export { authRoutes }
